<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :defaultData="defaultData"
    pathPrefix="civil"
  />
</template>
<script>
import { mapGetters } from "vuex";
import FormPage from "@/components/penal-retail-helpers/FormPage";
import civilCasesFormConfig from "@/helpers/civilCasesFormConfig";

let collectionName = "civilCases";

const findAttributeOptions = (rows, attributeName) => {
  return rows
    .find((r) => r.attributes.some((a) => a.attribute === attributeName))
    .attributes.find((a) => a.attribute === attributeName).extra.options;
};

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    defaultData() {
      let rows = civilCasesFormConfig(
        this,
        this.$store.state.AppActiveUser.role,
        true
      );

      let defaultCustomer =
        this.isCustomer || this.isSuperCustomer
          ? this.$store.state.AppActiveUser.customer
          : undefined;
      let defaultPartType = findAttributeOptions(rows, "partType").find(
        (x) => x.alias === "Demandada"
      );

      return {
        customer: defaultCustomer,
        partType: defaultPartType,
      };
    },
  },
  data() {
    let rows = civilCasesFormConfig(
      this,
      this.$store.state.AppActiveUser.role,
      true
    );

    return {
      customers: [],
      lawyers: [],
      collectionName,
      tabs: [{ name: `${collectionName}.tabs.general`, rows }],
    };
  },
  methods: {
    prevalidate() {
      return false;
    },
  },
};
</script>
