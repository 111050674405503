import statuses from "@/data/civilCases/civilCaseStatuses";

const procedures = [
  { id: "jUxy5JdMW5N37BucclMW", key: "ordinary" },
  { id: "R3Y23jg2cFkxpJjEGT8O", key: "verbal" },
  { id: "NHBbjVYx9T9pnrb70CZj", key: "exchangeRate" },
  { id: "sUOw8ro8GhjPML5LKzX9", key: "monitoring" },
  { id: "ITp9gnyINkJTfjBHXPXz", key: "execution" },
  { id: "QXQtBfVFQn9TW3iZ6EnK", key: "consumption" },
  { id: "6dA7vHSp1xnB2Xiy3dZz", key: "judgeRequirement" },
];

const partTypes = [
  { id: "Z2PZnnk75MBlexXa649l", key: "defendant" },
  { id: "IJd9PzQ0LcSZjGA9v3RY", key: "demanding" },
];

const videoConferenceOptions = [
  { id: "mpHbaNRCIaDdFplSTtll", key: "yes" },
  { id: "hwcuEpmPhaVSvWLyr9di", key: "no" },
  { id: "rV6FZMZUGTTOIO8SBmhI", key: "pending" },
  { id: "xeSijX7u7T0ypIhmqm76", key: "required" },
];

let rows = (self, role, isForCreate) => {
  let fields1 = [];
  let fields2 = [];
  let fields3 = [];
  let fields4 = [];

  const isReadOnly =
    (role === "customer" || role === "supercustomer") && !isForCreate;

  fields1.push({
    size: "1/2",
    type: "datetime",
    attribute: "date",
    validation: "required",
    readonly: isReadOnly,
  });
  fields1.push({
    size: "1/2",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction: () => {
        return self.allCustomers
          ? self.allCustomers.filter((c) =>
              c.casesTypes.map((c) => c.id).includes("civil")
            )
          : [];
      },
    },
    readonly: role === "customer" || role === "supercustomer" || !isForCreate,
  });
  fields1.push({
    size: "1/3",
    type: "enum",
    attribute: "procedure",
    validation: "required",
    extra: {
      enumType: "procedures",
      options: procedures,
    },
    readonly: isReadOnly,
  });

  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "procedureNumber",
    validation: "required",
    readonly: isReadOnly,
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "court",
    validation: "required",
    readonly: isReadOnly,
  });

  if (!isForCreate) {
    fields1.push({
      size: "1/2",
      type: "select",
      attribute: "lawyer",
      validation: "required",
      extra: {
        optionsFunction: () => {
          return self.allLawyers;
        },
      },
      readonly: isReadOnly,
    });
    fields1.push({
      size: "1/2",
      type: "enum",
      attribute: "status",
      validation: "required",
      extra: {
        enumType: "civilCaseStatuses",
        options: statuses,
        customSort(a, b) {
          return a.order - b.order;
        },
      },
      readonly: isReadOnly,
    });
  }

  fields2.push({
    size: "1/2",
    type: "datetime",
    attribute: "demandNotificationDate",
    readonly: isReadOnly,
  });
  fields2.push({
    size: "1/2",
    type: "datetime",
    attribute: "demandResponseDueDate",
    readonly: isReadOnly,
  });

  fields3.push({
    size: "1/4",
    type: "enum",
    attribute: "partType",
    validation: "required",
    extra: {
      enumType: "partTypes",
      options: partTypes,
    },
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "input",
    attribute: "otherPart",
    validation: "required",
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "input",
    attribute: "otherSolicitor",
    validation: "required",
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "input",
    attribute: "otherLawyer",
    validation: "required",
    readonly: isReadOnly,
  });

  fields3.push({
    size: "1/4",
    type: "datetime",
    attribute: "priorHearingDate",
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "enum",
    attribute: "priorHearingVideoConference",
    extra: {
      enumType: "priorHearingVideoConferenceOptions",
      options: videoConferenceOptions,
    },
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "datetime",
    attribute: "trialHearingDate",
    readonly: isReadOnly,
  });
  fields3.push({
    size: "1/4",
    type: "boolean",
    attribute: "judgment",
    readonly: isReadOnly,
  });

  fields4.push({
    size: "1/1",
    type: "text",
    attribute: "facts",
    validation: "required",
  });

  return [fields1, fields2, fields3, fields4].map((attributes) => ({
    attributes,
  }));
};

export default rows;
